<template>
  <div>
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <div
          size="sm"
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click="openModal"
          @click.prevent.stop="getTemplateMail()"
        >
          <font-awesome-icon icon="envelope" />
        </div>
      </template>
      <span>Envoyer E-mail</span>
    </v-tooltip>
    <!-- Modal pour ajouter les emails -->
    <v-dialog
      v-model="dialog"
      max-width="1800px"
      scrollable
      hide-overlay
      persistent
      content-class="custom-vuetify-dialog-header-envoi-email"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">{{
            'Email à envoyer :  ' + lotsToSend[index]
          }}</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="resetModalMailing"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>
        <v-card-text class="body-card center-text  mt-2">
          <div class="d-flex content-centre">
            <v-btn
              outlined
              class="color-btn-email"
              small
              @click.prevent.stop="sendMail"
              v-if="index < lotsToSend.length && index >= 0"
              :disabled="computedDisabledSend"
              :class="{ 'disabled-btn': computedDisabledSend }"
            >
              Envoyer
            </v-btn>
          </div>

          <div class="mail-modal-container">
            <div>
              <step-component :lot="lotsToSend[index]" />
            </div>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="footer-card">
          <div
            class="actionModel d-flex"
            :class="{ 'margin-block': index < lotsToSend.length && index > 0 }"
          >
            <v-btn
              class="mr-2"
              color="#704ad1"
              outlined
              small
              v-if="index < lotsToSend.length && index > 0"
              :disabled="loaderAcheteurMailObligee || loaderVendeurMailObligee"
              @click="getTemplateMailPrecedent()"
            >
              Précédent
            </v-btn>
            <div class="groupe-circle">
              <span
                v-for="(i, indexlot) in lotsToSend"
                :key="'lotIndex' + i"
                class="circleStep"
                :class="{ circleStepActive: indexlot == index }"
              ></span>
            </div>
            <v-btn
              class="ml-2"
              color="#704ad1"
              small
              outlined
              v-if="index < lotsToSend.length - 1 && index >= 0"
              :disabled="loaderAcheteurMailObligee || loaderVendeurMailObligee"
              @click="getTemplateMail()"
            >
              Suivant
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import stepComponent from './mailComponent/stepComponent.vue';
export default {
  components: { stepComponent },
  data() {
    return {
      loading: false,
      emailEnvoyer: [],
      index: -1,
      dialog: false
    };
  },
  props: {
    lotsToSend: { required: true },
    clientToSend: { required: true }
  },
  computed: {
    ...mapGetters([
      'getMailLoadingVertigo',
      'getSuccessMailsSent',
      'getErrorMailsSent',
      'getOtherErrorsMail',
      'MailDataAcheteurObligee',
      'MailDataVendeurObligee',
      'loaderVendeurMailObligee',
      'loaderAcheteurMailObligee',
      'arrayMsgSendMailObligee'
    ]),
    computedDisabledSend() {
      if (
        this.MailDataAcheteurObligee == null &&
        this.MailDataVendeurObligee == null
      ) {
        return true;
      }
      if (
        this.MailDataAcheteurObligee != null &&
        this.MailDataAcheteurObligee.vendeur_client == true
      ) {
        if (
          this.MailDataAcheteurObligee.title == null ||
          this.MailDataAcheteurObligee.title == '' ||
          this.MailDataAcheteurObligee.mail == null ||
          this.MailDataAcheteurObligee.mail == ''
        ) {
          return true;
        }
      }
      if (
        this.MailDataVendeurObligee != null &&
        this.MailDataVendeurObligee.vendeur_client == true
      ) {
        if (
          this.MailDataVendeurObligee.title == null ||
          this.MailDataVendeurObligee.title == '' ||
          this.MailDataVendeurObligee.mail == null ||
          this.MailDataVendeurObligee.mail == ''
        ) {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    ...mapActions([
      'sendMailVertigo',
      'getMailDataVAObligee',
      'sendMailDataVAObligee'
    ]),
    openModal() {
      this.dialog = true;
    },
    getTemplateMail() {
      this.index = this.index + 1;
      this.getMailDataVAObligee({
        lot: this.lotsToSend[this.index],
        vendeur_client: 'vendeur'
      });
      this.getMailDataVAObligee({
        lot: this.lotsToSend[this.index],
        vendeur_client: 'client'
      });
    },
    getTemplateMailPrecedent() {
      this.index = this.index - 1;
      this.getMailDataVAObligee({
        lot: this.lotsToSend[this.index],
        vendeur_client: 'vendeur'
      });
      this.getMailDataVAObligee({
        lot: this.lotsToSend[this.index],
        vendeur_client: 'client'
      });
    },
    sendMail() {
      if (
        this.MailDataAcheteurObligee != null &&
        this.MailDataAcheteurObligee.vendeur_client == true
      ) {
        this.sendMailDataVAObligee(this.MailDataAcheteurObligee);
      }
      if (
        this.MailDataVendeurObligee != null &&
        this.MailDataVendeurObligee.vendeur_client == true
      ) {
        this.sendMailDataVAObligee(this.MailDataVendeurObligee);
      }
      if (this.lotsToSend.length === this.index + 1) {
        this.dialog = false;
      } else {
        this.getTemplateMail();
      }
    },
    resetModalMailing() {
      this.$emit('resetCheckedTable', true);
      this.dialog = false;
      this.index = -1;
      this.emailEnvoyer = [];
    },
    addNewEmail() {
      this.emailEnvoyer.push({ email: null });
    },
    deleteEmail(index) {
      this.emailEnvoyer.splice(index, 1);
    }
  },
  watch: {
    arrayMsgSendMailObligee() {
      this.arrayMsgSendMailObligee.forEach(item => {
        if (item.vu == false) {
          this.flashMessage.show({
            status: item.type,
            title: 'LOT : ' + item.lot,
            clickable: true,
            time: 0,
            blockClass: 'custom-block-class',
            contentClass: 'custom-content-class',
            message: item.msg
          });
          item.vu = true;
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.mail-icon-commercial-lot {
  font-size: 11.5px;
  margin-right: 4px;
  color: #8d8cb7;
  &:hover {
    color: #4f4e93;
  }
}
.mail-modal-container {
  min-height: calc(100vh - 137px);
  overflow: hidden;
}
.footer-card {
  width: 1200px !important;
  .actionModel {
    overflow-x: auto;
    width: 1200px !important;
    justify-content: center;
    // margin-left: 110px;
    // &.margin-block {
    //   margin-left: 0px;
    // }
  }
}
.content-centre {
  justify-content: center;
}
.color-btn-email {
  background: rgb(112, 74, 209);
  color: #ffffff !important;
  caret-color: rgb(112, 74, 209);
}
.disabled-btn {
  color: #fff !important;
  background-color: #704ad187 !important;
}
.groupe-circle {
  width: 85%;
  max-width: 100%;
  overflow-x: auto;
  .circleStep {
    border-radius: 24px;
    margin: 2px;
    width: 15px;
    padding: 0px 0px 0px 15px;
    border: 1px solid #704ad1;
  }
}
</style>
<style lang="scss">
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4);
}
.tox-notifications-container {
  display: none !important;
}
</style>
